/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BehaviorType = (typeof BehaviorType)[keyof typeof BehaviorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BehaviorType = {
  competition: "competition",
} as const;
