/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type JobSoldHandlerConfigSchemaHandlerType =
  (typeof JobSoldHandlerConfigSchemaHandlerType)[keyof typeof JobSoldHandlerConfigSchemaHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobSoldHandlerConfigSchemaHandlerType = {
  job_sold_competition: "job_sold_competition",
} as const;
