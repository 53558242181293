/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type ConfirmedRaffleDrawingSchemaType =
  (typeof ConfirmedRaffleDrawingSchemaType)[keyof typeof ConfirmedRaffleDrawingSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConfirmedRaffleDrawingSchemaType = {
  competition_entry: "competition_entry",
} as const;
