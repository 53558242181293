/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MultiOptionHandlerConfigHandlerType =
  (typeof MultiOptionHandlerConfigHandlerType)[keyof typeof MultiOptionHandlerConfigHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiOptionHandlerConfigHandlerType = {
  multi_option_competition: "multi_option_competition",
} as const;
