/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AppointmentListResponse,
  AppointmentResponse,
  GetUpcomingAppointmentsParams,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get upcoming appointments for a technician within the specified time delta
 * @summary Get Upcoming Appointments
 */
export const getUpcomingAppointments = (
  tenantUuid: string,
  technicianUuid: string,
  params?: GetUpcomingAppointmentsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AppointmentListResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/upcoming-appointments/${technicianUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetUpcomingAppointmentsQueryKey = (
  tenantUuid: string,
  technicianUuid: string,
  params?: GetUpcomingAppointmentsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/upcoming-appointments/${technicianUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetUpcomingAppointmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUpcomingAppointments>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  technicianUuid: string,
  params?: GetUpcomingAppointmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUpcomingAppointments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetUpcomingAppointmentsQueryKey(tenantUuid, technicianUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUpcomingAppointments>>
  > = ({ signal }) =>
    getUpcomingAppointments(
      tenantUuid,
      technicianUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && technicianUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUpcomingAppointments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUpcomingAppointmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpcomingAppointments>>
>;
export type GetUpcomingAppointmentsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Upcoming Appointments
 */

export function useGetUpcomingAppointments<
  TData = Awaited<ReturnType<typeof getUpcomingAppointments>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  technicianUuid: string,
  params?: GetUpcomingAppointmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUpcomingAppointments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUpcomingAppointmentsQueryOptions(
    tenantUuid,
    technicianUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get a specific appointment by ID
 * @summary Get Appointment
 */
export const getAppointment = (
  tenantUuid: string,
  appointmentId: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AppointmentResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/appointments/${appointmentId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAppointmentQueryKey = (
  tenantUuid: string,
  appointmentId: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/appointments/${appointmentId}`,
  ] as const;
};

export const getGetAppointmentQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppointment>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  appointmentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAppointment>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAppointmentQueryKey(tenantUuid, appointmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppointment>>> = ({
    signal,
  }) => getAppointment(tenantUuid, appointmentId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && appointmentId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppointment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAppointmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAppointment>>
>;
export type GetAppointmentQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Appointment
 */

export function useGetAppointment<
  TData = Awaited<ReturnType<typeof getAppointment>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  appointmentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAppointment>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppointmentQueryOptions(
    tenantUuid,
    appointmentId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
