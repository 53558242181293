/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleCompetitionsName =
  (typeof BoostModuleCompetitionsName)[keyof typeof BoostModuleCompetitionsName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleCompetitionsName = {
  competitions: "competitions",
} as const;
