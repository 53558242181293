/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MembershipSoldQualifiedBehaviorOutboxSchemaType =
  (typeof MembershipSoldQualifiedBehaviorOutboxSchemaType)[keyof typeof MembershipSoldQualifiedBehaviorOutboxSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipSoldQualifiedBehaviorOutboxSchemaType = {
  membership_sold: "membership_sold",
} as const;
