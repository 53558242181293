/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type RaffleDrawingSchemaType =
  (typeof RaffleDrawingSchemaType)[keyof typeof RaffleDrawingSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RaffleDrawingSchemaType = {
  competition_entry: "competition_entry",
} as const;
