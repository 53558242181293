/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionLineItemFeedItemSchemaType =
  (typeof CompetitionLineItemFeedItemSchemaType)[keyof typeof CompetitionLineItemFeedItemSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionLineItemFeedItemSchemaType = {
  competition_line_item: "competition_line_item",
} as const;
