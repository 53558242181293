/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  Customer,
  ExportNearbyCustomersParams,
  GetNearbyCustomersParams,
  HTTPValidationError,
  NearbyCustomersListResponse,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Customer
 */
export const getCustomer = (
  tenantUuid: string,
  customerId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Customer>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/${customerId}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCustomerQueryKey = (
  tenantUuid: string,
  customerId: number
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/${customerId}`,
  ] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomerQueryKey(tenantUuid, customerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({
    signal,
  }) => getCustomer(tenantUuid, customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && customerId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomer>>
>;
export type GetCustomerQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Customer
 */

export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  customerId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomer>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCustomerQueryOptions(
    tenantUuid,
    customerId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Nearby Customers
 */
export const getNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NearbyCustomersListResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/nearby/${locationUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetNearbyCustomersQueryKey = (
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/nearby/${locationUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetNearbyCustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof getNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetNearbyCustomersQueryKey(tenantUuid, locationUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNearbyCustomers>>
  > = ({ signal }) =>
    getNearbyCustomers(
      tenantUuid,
      locationUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && locationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getNearbyCustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNearbyCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNearbyCustomers>>
>;
export type GetNearbyCustomersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Nearby Customers
 */

export function useGetNearbyCustomers<
  TData = Awaited<ReturnType<typeof getNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: GetNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNearbyCustomersQueryOptions(
    tenantUuid,
    locationUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Export nearby customers to CSV
 */
export const exportNearbyCustomers = (
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/customer/export_nearby/${locationUuid}`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getExportNearbyCustomersQueryKey = (
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/customer/export_nearby/${locationUuid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getExportNearbyCustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof exportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof exportNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getExportNearbyCustomersQueryKey(tenantUuid, locationUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof exportNearbyCustomers>>
  > = ({ signal }) =>
    exportNearbyCustomers(
      tenantUuid,
      locationUuid,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && locationUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof exportNearbyCustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportNearbyCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportNearbyCustomers>>
>;
export type ExportNearbyCustomersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Export nearby customers to CSV
 */

export function useExportNearbyCustomers<
  TData = Awaited<ReturnType<typeof exportNearbyCustomers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  locationUuid: string,
  params?: ExportNearbyCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof exportNearbyCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getExportNearbyCustomersQueryOptions(
    tenantUuid,
    locationUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
