/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostBaseUserSchemaRole =
  (typeof BoostBaseUserSchemaRole)[keyof typeof BoostBaseUserSchemaRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostBaseUserSchemaRole = {
  admin: "admin",
  technician: "technician",
  admin_technician: "admin_technician",
  lead_gen: "lead_gen",
} as const;
