/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Receive Twilio Sms Message
 */
export const receiveTwilioSmsMessage = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/callbacks/sms-message`, method: "POST", signal },
    options
  );
};

export const getReceiveTwilioSmsMessageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
    void
  > = () => {
    return receiveTwilioSmsMessage(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiveTwilioSmsMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof receiveTwilioSmsMessage>>
>;

export type ReceiveTwilioSmsMessageMutationError = ErrorType<unknown>;

/**
 * @summary Receive Twilio Sms Message
 */
export const useReceiveTwilioSmsMessage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getReceiveTwilioSmsMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
