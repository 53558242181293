/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type TglCreatedTriggerConfigSchemaTriggerType =
  (typeof TglCreatedTriggerConfigSchemaTriggerType)[keyof typeof TglCreatedTriggerConfigSchemaTriggerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TglCreatedTriggerConfigSchemaTriggerType = {
  tgl_created: "tgl_created",
} as const;
