/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleReportsName =
  (typeof BoostModuleReportsName)[keyof typeof BoostModuleReportsName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleReportsName = {
  reports: "reports",
} as const;
