/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type JobSoldTriggerConfigSchemaTriggerType =
  (typeof JobSoldTriggerConfigSchemaTriggerType)[keyof typeof JobSoldTriggerConfigSchemaTriggerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobSoldTriggerConfigSchemaTriggerType = {
  job_sold: "job_sold",
} as const;
