export type ENVIRONMENTS = "development" | "preview" | "production";

export const env = process.env.NEXT_PUBLIC_VERCEL_ENV as ENVIRONMENTS;

export const isLocal = env === "development" || !env;

export const isProduction = env === "production";

export const apiBaseURL = process.env.NEXT_PUBLIC_API_BASE_URL as string;
export const leadgenAPIBaseURL = process.env
  .NEXT_PUBLIC_LEAD_GEN_API_BASE_URL as string;
export const analyticsProxyUrl = process.env
  .NEXT_PUBLIC_ANALYTICS_PROXY_URL as string;

export const segmentWriteKey = process.env
  .NEXT_PUBLIC_SEGMENT_WRITE_KEY as string;

export const datadogRUMApplicationId = process.env
  .NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID as string;

export const datadogClientToken = process.env
  .NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string;

export const fullStoryOrgId = process.env
  .NEXT_PUBLIC_FULL_STORY_ORG_ID as string;

export const mapBoxApiKey = process.env.NEXT_PUBLIC_MAPBOX_API_KEY as string;
export const showLogger = true;

export const upsmithApiKey = process.env.NEXT_PUBLIC_UPSMITH_API_KEY;

export const encryptionKey = process.env.ENCRYPTION_KEY as string;

export const launchDarklyServerKey = process.env.LAUNCHDARKLY_SDK_KEY as string;

export const launchDarklyClientId = process.env
  .LAUNCHDARKLY_CLIENT_ID as string;

export const propelAuthUrl = process.env.NEXT_PUBLIC_PROPEL_AUTH_URL as string;
