/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type OpenAIVoices = (typeof OpenAIVoices)[keyof typeof OpenAIVoices];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpenAIVoices = {
  alloy: "alloy",
  shimmer: "shimmer",
  echo: "echo",
} as const;
