/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleLeaderboardName =
  (typeof BoostModuleLeaderboardName)[keyof typeof BoostModuleLeaderboardName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleLeaderboardName = {
  leaderboard: "leaderboard",
} as const;
