/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  HTTPValidationError,
  SearchIntegrationResultSchema,
  SearchIntegrationsParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Integrations
 */
export const searchIntegrations = (
  tenantUuid: string,
  params?: SearchIntegrationsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SearchIntegrationResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/integrations/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchIntegrationsQueryKey = (
  tenantUuid: string,
  params?: SearchIntegrationsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/integrations/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchIntegrationsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchIntegrations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchIntegrationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchIntegrations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchIntegrationsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchIntegrations>>
  > = ({ signal }) =>
    searchIntegrations(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchIntegrations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchIntegrationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchIntegrations>>
>;
export type SearchIntegrationsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Integrations
 */

export function useSearchIntegrations<
  TData = Awaited<ReturnType<typeof searchIntegrations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchIntegrationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchIntegrations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchIntegrationsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
