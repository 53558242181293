/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BrandTheme = (typeof BrandTheme)[keyof typeof BrandTheme];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrandTheme = {
  default: "default",
  pink: "pink",
} as const;
