/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type HandlerPointsAwardType =
  (typeof HandlerPointsAwardType)[keyof typeof HandlerPointsAwardType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HandlerPointsAwardType = {
  fixed: "fixed",
  variable: "variable",
} as const;
