/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureName = {
  lead_gen: "lead_gen",
  lead_gen_call: "lead_gen_call",
  lead_gen_text: "lead_gen_text",
} as const;
