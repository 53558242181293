/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type DashboardTimePeriod =
  (typeof DashboardTimePeriod)[keyof typeof DashboardTimePeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DashboardTimePeriod = {
  week: "week",
  month: "month",
} as const;
