/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type JobBookedTriggerConfigSchemaTriggerType =
  (typeof JobBookedTriggerConfigSchemaTriggerType)[keyof typeof JobBookedTriggerConfigSchemaTriggerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobBookedTriggerConfigSchemaTriggerType = {
  job_booked: "job_booked",
} as const;
