/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type TglCreatedHandlerConfigSchemaHandlerType =
  (typeof TglCreatedHandlerConfigSchemaHandlerType)[keyof typeof TglCreatedHandlerConfigSchemaHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TglCreatedHandlerConfigSchemaHandlerType = {
  tgl_created_competition: "tgl_created_competition",
} as const;
