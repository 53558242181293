/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CallPrompt,
  CallPromptList,
  GenerateTrainingMessagesParams,
  HTTPValidationError,
  PromptCreateRequest,
  PromptFeedbackRequest,
  TextPrompt,
  TextPromptList,
  TrainingMessageResponse,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Call Prompts
 */
export const getCallPrompts = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CallPromptList>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/call`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCallPromptsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/prompts/call`] as const;
};

export const getGetCallPromptsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCallPrompts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCallPrompts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCallPromptsQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCallPrompts>>> = ({
    signal,
  }) => getCallPrompts(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCallPrompts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCallPromptsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCallPrompts>>
>;
export type GetCallPromptsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Call Prompts
 */

export function useGetCallPrompts<
  TData = Awaited<ReturnType<typeof getCallPrompts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCallPrompts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCallPromptsQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Call Prompt
 */
export const createCallPrompt = (
  tenantUuid: string,
  promptCreateRequest: PromptCreateRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CallPrompt>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/call`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: promptCreateRequest,
      signal,
    },
    options
  );
};

export const getCreateCallPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCallPrompt>>,
    TError,
    { tenantUuid: string; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCallPrompt>>,
  TError,
  { tenantUuid: string; data: PromptCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCallPrompt>>,
    { tenantUuid: string; data: PromptCreateRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createCallPrompt(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCallPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCallPrompt>>
>;
export type CreateCallPromptMutationBody = PromptCreateRequest;
export type CreateCallPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Call Prompt
 */
export const useCreateCallPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCallPrompt>>,
    TError,
    { tenantUuid: string; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCallPrompt>>,
  TError,
  { tenantUuid: string; data: PromptCreateRequest },
  TContext
> => {
  const mutationOptions = getCreateCallPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Text Prompts
 */
export const getTextPrompts = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TextPromptList>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/text`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTextPromptsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/prompts/text`] as const;
};

export const getGetTextPromptsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTextPrompts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTextPrompts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTextPromptsQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTextPrompts>>> = ({
    signal,
  }) => getTextPrompts(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTextPrompts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTextPromptsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTextPrompts>>
>;
export type GetTextPromptsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Text Prompts
 */

export function useGetTextPrompts<
  TData = Awaited<ReturnType<typeof getTextPrompts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTextPrompts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTextPromptsQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Text Prompt
 */
export const createTextPrompt = (
  tenantUuid: string,
  promptCreateRequest: PromptCreateRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TextPrompt>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/text`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: promptCreateRequest,
      signal,
    },
    options
  );
};

export const getCreateTextPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTextPrompt>>,
    TError,
    { tenantUuid: string; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTextPrompt>>,
  TError,
  { tenantUuid: string; data: PromptCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTextPrompt>>,
    { tenantUuid: string; data: PromptCreateRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createTextPrompt(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTextPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTextPrompt>>
>;
export type CreateTextPromptMutationBody = PromptCreateRequest;
export type CreateTextPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Text Prompt
 */
export const useCreateTextPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTextPrompt>>,
    TError,
    { tenantUuid: string; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTextPrompt>>,
  TError,
  { tenantUuid: string; data: PromptCreateRequest },
  TContext
> => {
  const mutationOptions = getCreateTextPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Call Prompt
 */
export const updateCallPrompt = (
  tenantUuid: string,
  promptId: number,
  promptCreateRequest: PromptCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CallPrompt>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/call/${promptId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: promptCreateRequest,
    },
    options
  );
};

export const getUpdateCallPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCallPrompt>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCallPrompt>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCallPrompt>>,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest }
  > = (props) => {
    const { tenantUuid, promptId, data } = props ?? {};

    return updateCallPrompt(tenantUuid, promptId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCallPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCallPrompt>>
>;
export type UpdateCallPromptMutationBody = PromptCreateRequest;
export type UpdateCallPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Call Prompt
 */
export const useUpdateCallPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCallPrompt>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCallPrompt>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptCreateRequest },
  TContext
> => {
  const mutationOptions = getUpdateCallPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Call Prompt
 */
export const deleteCallPrompt = (
  tenantUuid: string,
  promptId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/call/${promptId}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteCallPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCallPrompt>>,
    TError,
    { tenantUuid: string; promptId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCallPrompt>>,
  TError,
  { tenantUuid: string; promptId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCallPrompt>>,
    { tenantUuid: string; promptId: number }
  > = (props) => {
    const { tenantUuid, promptId } = props ?? {};

    return deleteCallPrompt(tenantUuid, promptId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCallPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCallPrompt>>
>;

export type DeleteCallPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Call Prompt
 */
export const useDeleteCallPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCallPrompt>>,
    TError,
    { tenantUuid: string; promptId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCallPrompt>>,
  TError,
  { tenantUuid: string; promptId: number },
  TContext
> => {
  const mutationOptions = getDeleteCallPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Text Prompt
 */
export const updateTextPrompt = (
  tenantUuid: string,
  promptId: number,
  promptCreateRequest: PromptCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TextPrompt>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/text/${promptId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: promptCreateRequest,
    },
    options
  );
};

export const getUpdateTextPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTextPrompt>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTextPrompt>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTextPrompt>>,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest }
  > = (props) => {
    const { tenantUuid, promptId, data } = props ?? {};

    return updateTextPrompt(tenantUuid, promptId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTextPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTextPrompt>>
>;
export type UpdateTextPromptMutationBody = PromptCreateRequest;
export type UpdateTextPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Text Prompt
 */
export const useUpdateTextPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTextPrompt>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTextPrompt>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptCreateRequest },
  TContext
> => {
  const mutationOptions = getUpdateTextPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Text Prompt
 */
export const deleteTextPrompt = (
  tenantUuid: string,
  promptId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/text/${promptId}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteTextPromptMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTextPrompt>>,
    TError,
    { tenantUuid: string; promptId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTextPrompt>>,
  TError,
  { tenantUuid: string; promptId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTextPrompt>>,
    { tenantUuid: string; promptId: number }
  > = (props) => {
    const { tenantUuid, promptId } = props ?? {};

    return deleteTextPrompt(tenantUuid, promptId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTextPromptMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTextPrompt>>
>;

export type DeleteTextPromptMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Text Prompt
 */
export const useDeleteTextPrompt = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTextPrompt>>,
    TError,
    { tenantUuid: string; promptId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTextPrompt>>,
  TError,
  { tenantUuid: string; promptId: number },
  TContext
> => {
  const mutationOptions = getDeleteTextPromptMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Generate Training Messages
 */
export const generateTrainingMessages = (
  tenantUuid: string,
  promptId: number,
  params?: GenerateTrainingMessagesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TrainingMessageResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/${promptId}/training-messages`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGenerateTrainingMessagesQueryKey = (
  tenantUuid: string,
  promptId: number,
  params?: GenerateTrainingMessagesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/${promptId}/training-messages`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGenerateTrainingMessagesQueryOptions = <
  TData = Awaited<ReturnType<typeof generateTrainingMessages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  promptId: number,
  params?: GenerateTrainingMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof generateTrainingMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGenerateTrainingMessagesQueryKey(tenantUuid, promptId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof generateTrainingMessages>>
  > = ({ signal }) =>
    generateTrainingMessages(
      tenantUuid,
      promptId,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && promptId),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof generateTrainingMessages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GenerateTrainingMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof generateTrainingMessages>>
>;
export type GenerateTrainingMessagesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Training Messages
 */

export function useGenerateTrainingMessages<
  TData = Awaited<ReturnType<typeof generateTrainingMessages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  promptId: number,
  params?: GenerateTrainingMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof generateTrainingMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGenerateTrainingMessagesQueryOptions(
    tenantUuid,
    promptId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Submit Training Message Feedback
 */
export const submitTrainingMessageFeedback = (
  tenantUuid: string,
  promptId: number,
  promptFeedbackRequest: PromptFeedbackRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/prompts/${promptId}/training-messages/feedback`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: promptFeedbackRequest,
      signal,
    },
    options
  );
};

export const getSubmitTrainingMessageFeedbackMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTrainingMessageFeedback>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptFeedbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitTrainingMessageFeedback>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptFeedbackRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitTrainingMessageFeedback>>,
    { tenantUuid: string; promptId: number; data: PromptFeedbackRequest }
  > = (props) => {
    const { tenantUuid, promptId, data } = props ?? {};

    return submitTrainingMessageFeedback(
      tenantUuid,
      promptId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitTrainingMessageFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitTrainingMessageFeedback>>
>;
export type SubmitTrainingMessageFeedbackMutationBody = PromptFeedbackRequest;
export type SubmitTrainingMessageFeedbackMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Submit Training Message Feedback
 */
export const useSubmitTrainingMessageFeedback = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTrainingMessageFeedback>>,
    TError,
    { tenantUuid: string; promptId: number; data: PromptFeedbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof submitTrainingMessageFeedback>>,
  TError,
  { tenantUuid: string; promptId: number; data: PromptFeedbackRequest },
  TContext
> => {
  const mutationOptions =
    getSubmitTrainingMessageFeedbackMutationOptions(options);

  return useMutation(mutationOptions);
};
