/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationType = {
  competition_announcement: "competition_announcement",
  competition_activity: "competition_activity",
  competition_line_item_activity: "competition_line_item_activity",
  announcement: "announcement",
} as const;
