/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleSpotAwardsName =
  (typeof BoostModuleSpotAwardsName)[keyof typeof BoostModuleSpotAwardsName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleSpotAwardsName = {
  spot_awards: "spot_awards",
} as const;
