/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { HTTPValidationError } from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Log the recording status of the call.
 * @summary Recording Status
 */
export const logRecordingStatus = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/v1/tenants/${tenantUuid}/recording-status`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getLogRecordingStatusMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logRecordingStatus>>,
    TError,
    { tenantUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof logRecordingStatus>>,
  TError,
  { tenantUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logRecordingStatus>>,
    { tenantUuid: string }
  > = (props) => {
    const { tenantUuid } = props ?? {};

    return logRecordingStatus(tenantUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogRecordingStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof logRecordingStatus>>
>;

export type LogRecordingStatusMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Recording Status
 */
export const useLogRecordingStatus = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logRecordingStatus>>,
    TError,
    { tenantUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof logRecordingStatus>>,
  TError,
  { tenantUuid: string },
  TContext
> => {
  const mutationOptions = getLogRecordingStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Begin a call and return TwiML response to connect to Media Stream.
 * @summary Handle Incoming Call
 */
export const handleIncomingCall = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/tenants/${tenantUuid}/start-call`, method: "GET", signal },
    options
  );
};

export const getHandleIncomingCallQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/start-call`] as const;
};

export const getHandleIncomingCallQueryOptions = <
  TData = Awaited<ReturnType<typeof handleIncomingCall>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleIncomingCall>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHandleIncomingCallQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof handleIncomingCall>>
  > = ({ signal }) => handleIncomingCall(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof handleIncomingCall>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HandleIncomingCallQueryResult = NonNullable<
  Awaited<ReturnType<typeof handleIncomingCall>>
>;
export type HandleIncomingCallQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Handle Incoming Call
 */

export function useHandleIncomingCall<
  TData = Awaited<ReturnType<typeof handleIncomingCall>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof handleIncomingCall>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getHandleIncomingCallQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Receive a text message and generate a response.
 * @summary Receive Text
 */
export const receiveText = (
  tenantUuid: string,
  integrationUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/${integrationUuid}/receive-text`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getReceiveTextMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveText>>,
    TError,
    { tenantUuid: string; integrationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof receiveText>>,
  TError,
  { tenantUuid: string; integrationUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receiveText>>,
    { tenantUuid: string; integrationUuid: string }
  > = (props) => {
    const { tenantUuid, integrationUuid } = props ?? {};

    return receiveText(tenantUuid, integrationUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiveTextMutationResult = NonNullable<
  Awaited<ReturnType<typeof receiveText>>
>;

export type ReceiveTextMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Receive Text
 */
export const useReceiveText = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveText>>,
    TError,
    { tenantUuid: string; integrationUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof receiveText>>,
  TError,
  { tenantUuid: string; integrationUuid: string },
  TContext
> => {
  const mutationOptions = getReceiveTextMutationOptions(options);

  return useMutation(mutationOptions);
};
