/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type JobBookedBehaviorCreateSchemaType =
  (typeof JobBookedBehaviorCreateSchemaType)[keyof typeof JobBookedBehaviorCreateSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobBookedBehaviorCreateSchemaType = {
  job_booked: "job_booked",
} as const;
