/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MembershipStatus =
  (typeof MembershipStatus)[keyof typeof MembershipStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipStatus = {
  Active: "Active",
  Canceled: "Canceled",
  Deleted: "Deleted",
  Expired: "Expired",
  Suspended: "Suspended",
} as const;
