/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type JobBookedHandlerConfigSchemaHandlerType =
  (typeof JobBookedHandlerConfigSchemaHandlerType)[keyof typeof JobBookedHandlerConfigSchemaHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobBookedHandlerConfigSchemaHandlerType = {
  job_booked_competition: "job_booked_competition",
} as const;
