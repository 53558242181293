/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  HTTPValidationError,
  TextRequest,
  TextResponse,
  TextsRequest,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Generate and send text messages.
 * @summary Generate Texts
 */
export const generateTexts = (
  tenantUuid: string,
  textsRequest: TextsRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TextResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/generate-texts`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: textsRequest,
      signal,
    },
    options
  );
};

export const getGenerateTextsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateTexts>>,
    TError,
    { tenantUuid: string; data: TextsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateTexts>>,
  TError,
  { tenantUuid: string; data: TextsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateTexts>>,
    { tenantUuid: string; data: TextsRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return generateTexts(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateTextsMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateTexts>>
>;
export type GenerateTextsMutationBody = TextsRequest;
export type GenerateTextsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Texts
 */
export const useGenerateTexts = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateTexts>>,
    TError,
    { tenantUuid: string; data: TextsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateTexts>>,
  TError,
  { tenantUuid: string; data: TextsRequest },
  TContext
> => {
  const mutationOptions = getGenerateTextsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Generate and send a text message.
 * @summary Generate Text
 */
export const generateText = (
  tenantUuid: string,
  textRequest: TextRequest,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TextResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/generate-text`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: textRequest,
      signal,
    },
    options
  );
};

export const getGenerateTextMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateText>>,
    TError,
    { tenantUuid: string; data: TextRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateText>>,
  TError,
  { tenantUuid: string; data: TextRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateText>>,
    { tenantUuid: string; data: TextRequest }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return generateText(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateTextMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateText>>
>;
export type GenerateTextMutationBody = TextRequest;
export type GenerateTextMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Text
 */
export const useGenerateText = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateText>>,
    TError,
    { tenantUuid: string; data: TextRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateText>>,
  TError,
  { tenantUuid: string; data: TextRequest },
  TContext
> => {
  const mutationOptions = getGenerateTextMutationOptions(options);

  return useMutation(mutationOptions);
};
