/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type { HTTPValidationError } from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Mark Web Push Delivered
 */
export const markWebPushDelivered = (
  tenantUuid: string,
  webPushUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/web-pushes/${webPushUuid}/actions/mark-delivered`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getMarkWebPushDeliveredMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushDelivered>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markWebPushDelivered>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markWebPushDelivered>>,
    { tenantUuid: string; webPushUuid: string }
  > = (props) => {
    const { tenantUuid, webPushUuid } = props ?? {};

    return markWebPushDelivered(tenantUuid, webPushUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkWebPushDeliveredMutationResult = NonNullable<
  Awaited<ReturnType<typeof markWebPushDelivered>>
>;

export type MarkWebPushDeliveredMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Mark Web Push Delivered
 */
export const useMarkWebPushDelivered = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushDelivered>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof markWebPushDelivered>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const mutationOptions = getMarkWebPushDeliveredMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Mark Web Push Opened
 */
export const markWebPushOpened = (
  tenantUuid: string,
  webPushUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/web-pushes/${webPushUuid}/actions/mark-opened`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getMarkWebPushOpenedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushOpened>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markWebPushOpened>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markWebPushOpened>>,
    { tenantUuid: string; webPushUuid: string }
  > = (props) => {
    const { tenantUuid, webPushUuid } = props ?? {};

    return markWebPushOpened(tenantUuid, webPushUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkWebPushOpenedMutationResult = NonNullable<
  Awaited<ReturnType<typeof markWebPushOpened>>
>;

export type MarkWebPushOpenedMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Mark Web Push Opened
 */
export const useMarkWebPushOpened = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushOpened>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof markWebPushOpened>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const mutationOptions = getMarkWebPushOpenedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Mark Web Push Dismissed
 */
export const markWebPushDismissed = (
  tenantUuid: string,
  webPushUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/web-pushes/${webPushUuid}/actions/mark-dismissed`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getMarkWebPushDismissedMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushDismissed>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markWebPushDismissed>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markWebPushDismissed>>,
    { tenantUuid: string; webPushUuid: string }
  > = (props) => {
    const { tenantUuid, webPushUuid } = props ?? {};

    return markWebPushDismissed(tenantUuid, webPushUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkWebPushDismissedMutationResult = NonNullable<
  Awaited<ReturnType<typeof markWebPushDismissed>>
>;

export type MarkWebPushDismissedMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Mark Web Push Dismissed
 */
export const useMarkWebPushDismissed = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markWebPushDismissed>>,
    TError,
    { tenantUuid: string; webPushUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof markWebPushDismissed>>,
  TError,
  { tenantUuid: string; webPushUuid: string },
  TContext
> => {
  const mutationOptions = getMarkWebPushDismissedMutationOptions(options);

  return useMutation(mutationOptions);
};
